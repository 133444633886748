.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.image {
 width: 100%;
 object-fit: cover;
 margin: 0px 0px 30px 0px;
}

.imageCenter {
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.textDiv {
  margin: 15px 50px 75px 100px;
  background-color: lightblue;
}

.templateImage {
  margin: 0px 0px 10px 0px;
  width: 95%;
  height: auto;
}

.cardDiv {
  width: 100%;
}

.text {
  font-family: Impact, Charcoal, sans-serif;
  font-size: 50px;
  text-align: center;
  transform: scale(0.95);
}

.cardImage {
  width: 100px;
  height: 100px;
}

.text-banner {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  color: white;
  text-align: center;
  transform: scale(0.8);
  margin: 0px;
}
@media screen and (max-width: 700px) {
  .text-banner {
    font-size: 22px;
  }
  
}

.text-b {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  text-align: center;
  transform: scale(0.8);
}

.text-listing {
  font-family: Impact, Charcoal, sans-serif;
}

.cardText {
  font-size: 5.5vw;
  transform: scale(0.8);
}

.cardLine {
  line-height: 3pt;
}

.cardLineSmall {
  line-height: 5pt;
  font-size: 20px;
}

.link {
  color: "grey";
}

.saleBanner {
  margin: 2px;
}

@media screen and (max-width: 700px) {
  .saleBanner {
    font-size: 30px;
  }
  
}
